import * as actionTypes from "./actionsTypes";
import axios from "axios";
import axiosInstance from "../axios/axiosInstance";

var club_id = localStorage.getItem("club_id");
var user_id = localStorage.getItem("user_id");

export const handleClubVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CLUB_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};
export const handleEventVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EVENT_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};

export const fetchClubList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs");
      dispatch({
        type: actionTypes.FETCH_CLUB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchClubData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs/" + club_id);
      dispatch({
        type: actionTypes.FETCH_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateClubData = (updatedClubData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/clubs", updatedClubData);
      dispatch({
        type: actionTypes.UPDATE_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateClubClassData = (updatedClubClassData) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const updatedData = updatedClubClassData.map((obj) => {
    // Create a copy of the object to avoid mutating the original one
    const newObj = { ...obj };

    // Check if clubClassId is a string and matches the UUID pattern
    if (
      typeof newObj.clubClassId === "string" &&
      uuidRegex.test(newObj.clubClassId)
    ) {
      newObj.clubClassId = null; // Set clubClassId to null
    }

    return newObj; // Return the new object
  });
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/club_classes",
        updatedData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_CLASS_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchStandardClubClasses = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/club_classes/toBeAdded"
      );
      dispatch({
        type: actionTypes.STANDARD_CLUB_CLASSES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateClubAssociationData = (updatedClubAssociationData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/club_associations",
        updatedClubAssociationData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_ASSOCIATION_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchHcdefsData = (handicapdata) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_HCDEFS_DATA,
      payload: { data: handicapdata },
    });
  };
};

export const updateHcdefsData = (updatedHcdefsData, clone = false) => {
  let handicapDataPayload = { ...updatedHcdefsData };

  if (clone && updatedHcdefsData.cloneOption) {
    handicapDataPayload.handicapId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/handicap",
        handicapDataPayload
      );
      dispatch({
        type: actionTypes.UPDATE_HCDEFS_DATA,
        payload: response.data,
      });
      return { status: 1, message: response.data.data };
    } catch (error) {
      console.log(error);
      return { status: 0, message: error.response.data.message };
    }
  };
};

export const fetchHcdefsList = (showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/handicap/list?showHidden=" + showHidden
      );
      dispatch({
        type: actionTypes.FETCH_HCDEFS_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMenuData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/privilages/" + user_id);
      dispatch({
        type: actionTypes.FETCH_MENU_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchScorePointDefList = (isStandard) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scores"
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchStandardScorePointDefList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scores/standards"
      );
      dispatch({
        type: actionTypes.FETCH_STANDARD_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateScorePointDefList = (updatedScrorePointDef) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/scores",
        updatedScrorePointDef
      );
      dispatch({
        type: actionTypes.UPDATE_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      return { status: 0, data: error.response.data };
    }
  };
};

//Events
export const createEventData = (eventData, eventId = null) => {
  return async (dispatch) => {
    try {
      // Create a shallow copy of eventData to modify for the API request
      const eventDataCopy = { ...eventData };
      if (eventId) {
        eventDataCopy.eventId = null;
        eventDataCopy.cloneOption = eventId;
      }
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/events",
        eventDataCopy
      );
      dispatch({
        type: actionTypes.CREATE_EVENT_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};

export const fetchEventList = (type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/events?type=" + type
      );
      if (type == 1) {
        dispatch({
          type: actionTypes.FETCH_EVENT_KB_LIST,
          payload: response.data,
        });
      } else if (type == 2) {
        dispatch({
          type: actionTypes.FETCH_EVENT_OTB_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_EVENT_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateEventData = (eventData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/events",
        eventData
      );
      dispatch({
        type: actionTypes.UPDATE_EVENT_DATA,
        payload: eventData,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};
export const selectedEventData = (eventData, type = 0) => {
  return async (dispatch) => {
    if (type == 1) {
      dispatch({
        type: actionTypes.SELECTED_EVENT_KB_DATA,
        payload: { data: eventData },
      });
    } else if (type == 2) {
      dispatch({
        type: actionTypes.SELECTED_EVENT_OTB_DATA,
        payload: { data: eventData },
      });
    } else {
      dispatch({
        type: actionTypes.SELECTED_EVENT_DATA,
        payload: { data: eventData },
      });
    }
  };
};

//Series-KB
export const selectedSeriesData = (seriesData) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_SERIES_DATA,
      payload: { data: seriesData },
    });
  };
};

export const fetchSeriesList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/list/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchSeriesNameList = (eventId, type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/listSeriesNames/" + eventId
      );
      if (type == 1) {
        dispatch({
          type: actionTypes.FETCH_SERIES_KB_NAME_LIST,
          payload: response.data,
        });
      } else if (type == 2) {
        dispatch({
          type: actionTypes.FETCH_SERIES_OTB_NAME_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_SERIES_NAME_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      return error.response.data.message;
    }
  };
};
export const emptySeriesNameList = (type = 0) => {
  return (dispatch) => {
      if (type == 1) {
        dispatch({
          type: "EMPTY_KB_NAME_LIST",
          payload: {data: []},
        });
      } else if (type == 2) {
        dispatch({
          type: "EMPTY_OTB_NAME_LIST",
          payload: {data: []},
        });
      } else {
        dispatch({
          type: "EMPTY_NAME_LIST",
          payload: {data: []},
        });
      }
   
  };
};
export const fetchSeriesData = (seriesId, type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      if(type == 1){
        dispatch({
          type: actionTypes.FETCH_SERIES_KB_DATA,
          payload: response.data,
        });
        return response.data
      }else if(type == 2){
        dispatch({
          type: actionTypes.FETCH_SERIES_OTB_DATA,
          payload: response.data,
        });
        return response.data
      }else{
        dispatch({
          type: actionTypes.FETCH_SERIES_DATA,
          payload: response.data,
        });
        return response.data
      }
      
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchIncompleteSeriesData = (eventId, cloneOption) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/incomplete/" +
          eventId +
          "?cloneOption=" +
          cloneOption
      );
      dispatch({
        type: actionTypes.FETCH_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const deleteIncompleteSeriesData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.DELETE_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesData = (seriesData, cloneOption = 0) => {
  let seriesDataPayload = { ...seriesData };
  if (cloneOption) {
    seriesDataPayload.cloneOption = seriesData.seriesId;
    seriesDataPayload.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series",
        seriesDataPayload
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesData = (seriesData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/series/" + seriesData.seriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClasses = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/fleetClass",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptions = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/resultOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocations = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" +
          club_id +
          "/series/" +
          seriesId +
          "/resultOptionAllocation",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOption = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/scoringOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//Series-OTB
export const fetchSeriesOTBList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/list/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchSeriesOTBData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesOTBData = (seriesData, cloneOption = 1) => {
  if (!cloneOption) {
    seriesData.cloneOption = seriesData.seriesId;
    seriesData.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesOTBData = (seriesData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/series/" + seriesData.seriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClassesOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/fleetClass",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/resultOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocationsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" +
          club_id +
          "/series/" +
          seriesId +
          "/resultOptionAllocation",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOptionOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/scoringOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//fetch penalty list
export const fetchScorePenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scorePenalties"
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_PENALITY_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const SaveScorePenaltyList = (scorePenaltyListData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/scorePenalties",
        scorePenaltyListData
      );
      dispatch({
        type: actionTypes.UPDATE_SCORE_PENALITY_DEF_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchPenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scorePenalties"
      );
      dispatch({
        type: actionTypes.FETCH_PENALTY_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//EVENTS ENDS HERE

//FETCH TES DATA STARTS HERE
export const fetchTESEvents = (type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: [club_id],
      });
      dispatch({
        type: actionTypes.FETCH_TES_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTESAssoEvents = (clubIds, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: clubIds,
      });
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESAssoSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchlogout = (requestData) => {
  return async (dispatch) => {
    //{}
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/logout",
        requestData
      );
      dispatch({
        type: actionTypes.FETCH_LOGOUT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchrefreshToken = async () => {
  try {
    const requestData = {
      token: localStorage.getItem("refreshtoken"),
      userID: localStorage.getItem("user_id"),
    };
    const response = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "api/auth/refreshToken",
      requestData
    );
    return { status: 1, data: response.data.accessToken };
  } catch (error) {
    console.log(error);
    return { status: 0, data: error.response.data.message };
  }
};

//Race Days
export const createOrUpdateRaceDays = (RaceData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/RaceCalendar",
        RaceData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_DAYS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const fetchRaceDays = (eventId, seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceCalendar/" + eventId + "/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceDaysSeries = (eID) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceCalendar/" + eID
      );
      dispatch({
        type: actionTypes.FETCH_RACE_DAYS_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Race Session
export const fetchSeriesByRaceDate = (eventId, raceDate) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceCalendar/series/" +
          eventId +
          "?raceDate=" +
          raceDate
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OF_RACE_DATE,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateSeriesOfRaceDate = (seriesData) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SERIES_OF_RACE_DATE,
      payload: { data: seriesData },
    });
  };
};

export const fetchRaceSession = (eventId, sessionDate) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_RACE_SESSION_REQUEST" });

    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceSession/" + eventId + "/" + sessionDate
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionById = (raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceSession/raceSessionId/" + raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_ID,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionBySeries = (eventId, seriesId, sessionDate) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/" +
          eventId +
          "/" +
          seriesId +
          "/" +
          sessionDate
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptyRaceSession = () => {
  return (dispatch) => {
    dispatch({
      type: "EMPTY",
      payload: { data: [] },
    });
  };
};

export const fetchRaceSessionNamesBySeries = (
  eventId,
  seriesId,
  sessionDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/list/" +
          eventId +
          "/" +
          seriesId +
          "/" +
          sessionDate
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_NAMES_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionByEvent = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceSession/events/" + eventId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_EVENT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionBySeriesOnly = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/RaceSession/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_SERIES_ONLY,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updatedRaceSession = (raceSessionData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATED_RACE_SESSION,
      payload: { data: raceSessionData },
    });
  };
};

export const createUpdateRaceSession = (raceSessionData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/RaceSession",
        raceSessionData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const moveRaceSession = (
  eventId,
  raceSessionId,
  sourceDate,
  destDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/" +
          eventId +
          "/" +
          raceSessionId +
          "/" +
          sourceDate +
          "/" +
          destDate
      );
      dispatch({
        type: actionTypes.MOVE_RACE_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const changeRaceDate = (
  eventId,
  raceSessionDetailId,
  sourceDate,
  destDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/Race" +
          eventId +
          "/" +
          raceSessionDetailId +
          "/" +
          sourceDate +
          "/" +
          destDate
      );
      dispatch({
        type: actionTypes.CHANGE_RACE_DATE,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const cloneLastRaceDay = (eventId, raceDate, initialRaceSession) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/clone/" +
          eventId +
          "/" +
          raceDate
      );
      const clonedRaceSess = response.data.data.map((x) => {
        return {
          ...x,
          cloneOption: x.raceSessionId,
          raceSessionId: "",
          sessionDate: raceDate,
          raceSessionsDetailList: x.raceSessionsDetailList.map((y) => {
            return {
              ...y,
              raceSessionDetailId: "",
              raceNo: y.raceNo + 1,
            };
          }),
        };
      });
      console.log(clonedRaceSess);
      // const updatedData = [...initialRaceSession, ...clonedRaceSess]
      let updatedRes = response.data;
      // updatedRes.data =[...updatedData]
      updatedRes.data = [...clonedRaceSess];

      dispatch({
        type: actionTypes.CLONE_LAST_RACE_DAY,
        payload: updatedRes,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const cloneLastRaceDayNormalRace = (
  eventId,
  normalRaceDay,
  raceDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/RaceSession/cloneNormalRace/" +
          eventId +
          "/" +
          normalRaceDay +
          "/" +
          raceDate
      );
      const clonedRaceSess = response.data.data.map((x) => {
        return {
          ...x,
          cloneOption: x.raceSessionId,
          raceSessionId: "",
          sessionDate: raceDate,
          raceSessionsDetailList: x.raceSessionsDetailList.map((y) => {
            return {
              ...y,
              raceSessionDetailId: "",
              raceNo: y.raceNo + 1,
            };
          }),
        };
      });
      console.log(clonedRaceSess);
      // const updatedData = [...initialRaceSession, ...clonedRaceSess]
      let updatedRes = response.data;
      // updatedRes.data =[...updatedData]
      updatedRes.data = [...clonedRaceSess];

      dispatch({
        type: actionTypes.CLONE_LAST_RACE_DAY_NORMAL_RACE,
        payload: updatedRes,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createUpdateRaceEntrants = (entrants) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/Entrants",
        entrants
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_ENTRANTS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchRaceEntrants = (seriesId, racesessionid) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/Entrants/" + seriesId + "/" + racesessionid
      );
      dispatch({
        type: actionTypes.FETCH_RACE_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchSeriesEntrants = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/Entrants/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_RACE_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const storeTESEntrants = (eventId, seriesId, TESEventId, TESSerisId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/TESEntrants/" +
          eventId +
          "/" +
          seriesId +
          "/" +
          TESEventId +
          "/" +
          TESSerisId
      );
      dispatch({
        type: actionTypes.STORE_TES_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTESEntrants = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/TESEntrants/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_TES_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchEntrantsEvents = (type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/events?type=" + type
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_EVENT_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Series-KB
export const fetchEntrantsSeriesList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/listSeriesNames/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_SERIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchEntrantsSeriesData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const triggerCSVImport = () => ({
  type: actionTypes.TRIGGER_CSV_IMPORT,
});

export const triggerTESImport = () => ({
  type: actionTypes.TRIGGER_TES_IMPORT,
});

export const entrantImportSource = (entrantImportSource) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ENTRANT_IMPORT_SOURCE,
      payload: entrantImportSource,
    });
  };
};
export const fetchSignedOn = (seriesId, date, type) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/TESSignOnEntrants/" +
          type +
          "/" +
          seriesId +
          "/" +
          date
      );
      dispatch({
        type: actionTypes.FETCH_SIGNED_ON,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Results
export const fetchSeriesByRaceSession = (raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/raceSession/" + raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_BY_RACE_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptySeriesList = () => {
  return  (dispatch) => {
      dispatch({
        type: "EMPTY",
        payload: {data: []},
      });
  };
};

export const fetchResultsBySession = (fleetId, raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/results/" + fleetId + "/" + raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_RESULTS_BY_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchSeriesScore = (seriesId, fleetId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/results/SeriesScores/" +
          seriesId +
          "/" +
          fleetId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_SCORES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchPreviousRaceSeries = (seriesId, raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/Entrants/pre/" +
          seriesId +
          "/" +
          raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_PREVIOUS_RACE_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchOffsetEntrants = (entrants) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/EntrantsOffset",
        entrants
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANT_OFFSETS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const deleteRaceSessionDetail = (detailId, isDeleteParent = true) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/" + club_id + "/RaceSession/" + detailId + "?isDeleteParent=" + isDeleteParent
      );
      dispatch({
        type: actionTypes.DELETE_RACE_SESSION_DETAIL,
        payload: response.data,
      });
      return { status: true, data: response.data };

    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };
    }
  };
};

export const updateRaceSessionDetailStatus = (detailId, status) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/RaceSession/" + detailId + "/" + status
      );
      dispatch({
        type: actionTypes.UPDATE_RACE_SESSION_DETAIL,
        payload: response.data,
      });
      return { status: true, data: response.data };

    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };

    }
  };
};

export const abandonRace = (detailId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/RaceSession/Abandon/" + detailId
      );
      dispatch({
        type: actionTypes.ABANDON_RACE,
        payload: response.data,
      });
      return { status: true, data: response.data };

    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };

    }
  };
};

export const fetchSavedOffsets = (seriesId, raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/EntrantsOffset/" +
          seriesId +
          "/" +
          raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_OFFSETS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchPublishedResultsBySession = (fleetId, raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/results/published/" +
          fleetId +
          "/" +
          raceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_PUBLISHED_RESULTS_BY_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const insertPublishedResultsBySession = (results, seriesId, option) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/results/add/" + seriesId+"/"+option,
        results
      );
      dispatch({
        type: actionTypes.ADD_PUBLISHED_RESULTS_BY_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchPublishedResultsBySeries = (eventId, seriesId, fleetId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/publishSeriesScore/get/" 
          + eventId+"/" + seriesId+"/"+fleetId
      );
      dispatch({
        type: actionTypes.FETCH_PUBLISHED_RESULTS_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const insertPublishedResultsBySeries = (results, eventId, seriesId, fleetId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/publishSeriesScore/add/"+ eventId+"/" + seriesId+"/"+fleetId,
        results
      );
      dispatch({
        type: actionTypes.ADD_PUBLISHED_RESULTS_BY_SERIES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const updateResults = (results, fleetId, raceSessionId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/results/update/"+ fleetId + "/" + raceSessionId,
        results
      );
      dispatch({
        type: actionTypes.UPDATE_RESULTS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};


export const fetchAllPenalties = () => {
	  return async (dispatch) => {
	    try {
	      const response = await axiosInstance.get(
	        "api/clubs/" +
	          club_id +
	          "/scorePenalties/All"
	      );
	      dispatch({
	        type: actionTypes.FETCH_ALL_PENALTIES,
	        payload: response.data,
	      });
	    } catch (error) {
	      console.log(error);
	      return error.response.data.message;
	    }
	  };
	};
	
	
	export const deleteAllEntrants = (seriesId,sessionId) => {
		  return async (dispatch) => {
		    try {
		      const response = await axiosInstance.delete(
		        "api/clubs/" + club_id + "/Entrants/" + seriesId + "/" + sessionId
		      );
		      dispatch({
		        type: actionTypes.DELETE_ALL_ENTRANTS,
		        payload: response.data,
		      });
		      return { status: true, data: response.data };

		    } catch (error) {
		      console.log(error);
		      return { status: false, data: error.response.data.message };
		    }
		  };
		};

    export const addHiddenColumns = (data) => {
      return async (dispatch) => {
        try {
          const response = await axiosInstance.post(
            "api/clubs/" + club_id + "/publishedResultColumn/add",
            data
          );
          dispatch({
            type: actionTypes.ADD_HIDDEN_COLUMNS,
            payload: response.data,
          });
          return { status: true, data: response.data };
        } catch (error) {
          console.log(error);
          return error.response.data.message;
        }
      };
    };

    export const fetchHiddenColumns = (fleetId, raceSessionId) => {
      return async (dispatch) => {
        try {
          const response = await axiosInstance.get(
            "api/clubs/" + club_id + "/publishedResultColumn/show/" + fleetId + "/" + raceSessionId,
          );
          dispatch({
            type: actionTypes.FETCH_HIDDEN_COLUMNS,
            payload: response.data,
          });
          return { status: true, data: response.data };
        } catch (error) {
          console.log(error);
          return error.response.data.message;
        }
      };
    };
    